<script>
import Layout from '@layouts/main'

import { setComputed } from '@state/helpers'

import FaqCollapse from "@components/FaqCollapse";

import { getScrollTop } from '@utils/scroll'



export default {
  components: { Layout, FaqCollapse },
  data() {
    return {
      scrollTop: 0,
      menuActiveAlias: '',
      menuIsOpen: false,
      faqListIndex: -1,
      text: {
        'zh-TW': {
          slogan: {
            title: "竭誠歡迎<br class='d-none d-lg-block'>您與我們聯繫",
            content: "Extension 1 by 橘色誠摯待客<br>若您有任何疑問，可先參考下方『常見問題』的解答<br>亦可於上班時間聯絡客服人員或來信至信箱<br>我們將竭誠為您服務"
          }
        },
        'en': {
          slogan: {
            title: "You're welcome <br class='d-none d-lg-block'> to contact us",
            content: "Extension 1 values our guests<br>If you have any questions, please see the FAQ<br>You can also call us during office hours or email us,<br>we're at your service."
          }
        }
      },
      faqList: {
        'zh-TW':[
          {
            title: 'Extension 1 by 橘色 是否有個人低消？',
            content: '您好，我們低消是500元/人。如需共鍋，可在訂位時註明或告知服務人員。',
          },
          {
            title: 'Extension 1 by 橘色 是否有附設停車場？是否有大眾運輸前往方式？',
            content: '您好，我們附近周圍、同街及店鋪後方皆有數個停車場，提供給您參考。而若要搭乘捷運的話，請搭乘文湖線西湖站2號出口，約莫5-10分鐘路程，謝謝。',
          },
          {
            title: 'Extension 1 by 橘色 是否有壽星優惠活動？',
            content: '您好，不好意思目前無生日優惠活動。<br>但可以帶蛋糕來店慶生，不會另加收蛋糕的清潔費用。',
          },
          {
            title: 'Extension 1 by 橘色 是否有刷卡服務是否有刷卡及其他優惠？',
            content: '您好，我們有提供刷卡服務，但目前沒有與銀行搭配合作優惠以及其他優惠活動。',
          },
          {
            title: '除了上述事項，我仍有其他問題，我可以透過什麼方式詢問？',
            content:
              `您好，除了可以透過官方聯繫信箱（orangeshabu@gmail.com），也可以透過官方臉書專頁（<a target="_blank" href='https://lihi1.com/aXnnd'>https://lihi1.com/aXnnd</a>）詢問，我們會有專人儘速與您聯繫`,
          },
        ],
        'en': [
          {
            title: 'Is there any minimum order per person?',
            content: 'Our minimum order is 500 NTD per person. If you’d like to share one pot, please make a note when you make your reservation, or inform our service staff.',
          },
          {
            title: 'Do you provide parking? Or how do I get there by public transportation?',
            content: 'There are several parking lots in this neighborhood. For public transportation, take MRT Wenhu Line and get off at Xihu Station. Take xit 2 and our restaurant is about a 5-10 minute walk away.',
          },
          {
            title: 'Are there any birthday promotions?',
            content: "We're sorry but we don't have such promotions so far.<br>You're welcome to bring your own birthday cake with no additional service charge.",
          },
          {
            title: 'Can I pay by credit card, and are there any other discount deals?',
            content: 'We take credit cards but there are no discount deals or promotional activities so far.',
          },
          {
            title: 'If I have other questions, how can I contact you?',
            content:
              `You can contact us by email  (orangeshabu@gmail.com), or via our Facebook page (https://lihi1.com/aXnnd), we’ll get back to you ASAP.`,
          },
        ]
      },
    }
  },
  computed: {
    ...setComputed,
    textModule() {
      return this.$route.params.lang === "zh-TW"? this.text['zh-TW']:this.text['en']
    },
    faqListModule() {
      return this.$route.params.lang === "zh-TW"? this.faqList['zh-TW']:this.faqList['en']
    },
    bannerImageStyle() {
      const windthWidth = this.window_w;
      const scrollTop = this.scrollTop;

      if(windthWidth > 991) {
        return {
          transform: `translateY(-${scrollTop * 0.1}px)`,
          '-webkit-transform': `translateY(-${scrollTop * 0.1}px)`,
        }
      }else {
        return {}
      }
    },
    bannerLogoStyle() {
      const windthWidth = this.window_w;
      const scrollTop = this.scrollTop;

      if(windthWidth > 991) {
        return {
          transform: `translateY(${scrollTop * 0.1}px)`,
          '-webkit-transform': `translateY(${scrollTop * 0.1}px)`,
        }
      }else {
        return {}
      }
    },
  },
  mounted() {
    this.getWindowWidth();
    window.addEventListener('resize', this.getWindowWidth);
    document.addEventListener("scroll", this.onScroll, { passive: false });
  },
  destroyed() {
    window.removeEventListener('resize', this.getWindowWidth);
    document.removeEventListener("scroll", this.onScroll, { passive: false });
  },
  methods: {
    onScroll() {
      this.scrollTop = this.getScrollTop();
    },
    getWindowWidth(){
      this.window_w = document.body.clientWidth
    },
    setActiveAlias(alias) {
      this.menuActiveAlias = alias
    },
    getScrollTop
  },
}
</script>

<template>
  <Layout>
    <section :class="$style.sectionFaq" class="section" data-section-logo-color="#000">
      <div class="row">
        <div :class="$style.imgBox">
          <img :class="$style.image" :style="bannerLogoStyle" src="@assets/images/faq/logo.svg">
          <img
            :class="$style.banner"
            :style="bannerImageStyle"
            src="@assets/images/faq/banner.jpg"
          >
          <img :class="$style.bannerMobile" :style="bannerImageStyle" src="@assets/images/faq/banner_mobile.jpg">
        </div>
        <div :class="$style.textBox">
          <h2
            :class="$style.title" v-html="textModule.slogan.title"></h2>
          <p :class="$style.content" v-html="textModule.slogan.content"></p>
          <div class="row flex-column flex-lg-row" :class="$style.contactList">
            <div class="col" :class="$style.contactItem">
              <div :class="$style.contactTitle">{{$t('global.CUSTOMER_SERVICE') + ' ' + $t('global.HOTLINE')}}</div>
              <a target="_blank" href="tel:+886 2-2711-4636">+886-2-2711-4636</a>
            </div>
            <div class="col" :class="$style.contactItem">
              <div :class="$style.contactTitle">{{$t('global.CUSTOMER_SERVICE') + ' ' + $t('global.EMAIL')}}</div>
              <a target="_blank" href="mailto:orangeshabu@gmail.com">orangeshabu@gmail.com</a>
            </div>
          </div>
          <div class="text-center text-lg-left">
            <div :class="$style.faqTitle">{{$t('global.NAV_FAQ')}}</div>
          </div>
          <FaqCollapse v-model="faqListIndex" :list="faqListModule"/>
        </div>
      </div>
    </section>
  </Layout>
</template>
<style lang="scss" module>
  .sectionFaq {
    .imgBox {
      margin-top: 607px;
      margin-bottom: 183px;
      width: calc(100vw - 57.405vw);
      position: relative;
      z-index: 1;
      @media screen and (max-width: 1420px) {
        width: calc(40vw);
      }
      @include touch {
        margin-top: 201px;
        margin-bottom: 60px;
        width: 100%;
      }
      .image {
        position: absolute;
        z-index: -1;
        right: -3.134%;
        top: -29.37%;
        width: calc(100vw - 57.405vw);
        @include touch {
          right: 70px;
          top: -95px;
          width: 300px;
        }
      }
      .banner {
        max-width: 100%;
        display: block;
        @include touch {
          display: none;
        }
      }
      .bannerMobile {
        display: none;
        @include touch {
          display: block;
        }
      }
    }
    .textBox {
      padding-top: 400px;
      width: 57.405vw;
      /* padding-left: 9.877vw; */
      padding-right: 5.104vw;
      padding-left: 5.104vw;
      margin-bottom: 60px;
      margin-left: auto;
      margin-right: 0;
      width: calc(700px + 10.208vw);
      @media screen and (max-width: 1420px) {
        width: calc(60vw);
      }
      @include touch {
        padding-top: 0;
        width: 100%;
      }
      .title {
        font-size: 46px;
        line-height: 64px;
        letter-spacing: 5.06px;
        margin-bottom: 77px;
        font-weight: 500;
        @include touch {
          font-size: 23px;
          line-height: 30px;
          letter-spacing: 4.32px;
          text-align: center;
          margin-bottom: 23px;
        }
      }
      .content {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1.12px;
        font-weight: 500;
        margin-bottom: 100px;
        @include touch {
          font-size: 13px;
          line-height: 22px;
          letter-spacing: 1.56px;
          margin-bottom: 40px;
          text-align: center;
        }
      }
    }
    .contactList {
      margin-bottom: 138px;
      @include touch {
        margin-bottom: 60px;
      }
      .contactItem {
        font-family: Palatino, 'Noto Serif TC', serif;
        font-size: 22px;
        line-height: 31px;
        letter-spacing: 1.76px;
        @include touch {
          text-align: center;
          margin-bottom: 40px;
        }
        .contactTitle {
          font-size: 16px;
          line-height: 40px;
          letter-spacing: 1.92px;
          font-weight: 500;
          @include touch {
            font-size: 19px;
            line-height: 29px;
            letter-spacing: 2.28px;
            margin-bottom: 20px;
          }
        }
        a {
          color: #000;
          font-size: 22px;
          line-height: 31px;
          letter-spacing: 1.76px;
          @include touch {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 1.12px;
          }
          &:hover {
            text-decoration: none;
            color: #000;
          }
        }
      }
    }
    .faqTitle {
      color: #f2f2f2;
      background: #000000;
      border: 1px solid #000000;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 3.6px;
      margin: 0 auto;
      display: inline-block;
      margin-bottom: 109px;
      padding: 11.5px 19px;
      font-weight: 500;
      @include touch {
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 3.6px;
        padding: 11px 19px;
        margin-bottom: 50px;
      }
    }
  }
</style>
