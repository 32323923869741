<script>
export default {
  props: {
    className: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    onClickHeader(index) {
      const Index = index === this.value ? -1 : index;
      this.$emit('input', Index);
    },
  },
};
</script>
<template>
  <div :class="[$style.faqCollapse, className]">
    <template v-for="(item, $index) in list">
      <div
        :key="$index"
        :class="[$style.collapseBox, { [$style.isActive]: $index === value }]"
      >
        <div :class="$style.collapseHeader" @click="onClickHeader($index)">
          <span><span :class="$style.q">Q<sub>{{$index + 1}}</sub></span>
            <span :class="$style.text">{{item.title}}</span>
          </span>
          <img v-if="$index === value" :class="$style.icon" src="@assets/images/icon/minus_black.svg">
          <img v-else :class="$style.icon" src="@assets/images/icon/plus_black.svg">
        </div>
        <div :class="$style.collapseContent"
             :style="{ maxHeight: $index === value ? '210px' : 0 }"
        >
          <div :class="$style.content" v-html="item.content"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" module>
  .faqCollapse {
    @include touch {
      margin: 0 20px;
    }
    .collapseBox {
      &.isActive {
        .collapseHeader {
          // color: #fff;
        }
      }
      .collapseHeader {
        background: transparent;
        padding: 18.5px 49px 18.5px 27px;
        letter-spacing: 1.92px;
        cursor: pointer;
        color: #000;
        font-size: 16px;
        align-items: center;
        justify-content: space-between;
        display: flex;
        position: relative;
        font-weight: 500;
        border: 1px solid rgba(#707070, 0.7);
        margin-top: -1px;
        @include touch {
          font-size: 15px;
          line-height: 25px;
          letter-spacing: 1.8px;
          padding: 10px 3rem 10px 3.3rem;
          text-indent: -1.8rem;
        }
        .icon {
          position: absolute;
          top: 50%;
          right: 24px;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          @include touch {
            width: 13px;
            height: 13px;
          }
        }
        .q {
          font-family: Palatino, sans-serif;
          margin-right: 0.5rem;
        }
        &:hover {
          // color: #c0b4a7;
        }
      }
      .collapseContent {
        letter-spacing: 0.05rem;
        overflow: hidden;
        // transition: max-height 0.3s ease-in-out;
        // border: solid 1px #707070;
        background-color: #5d5d5d;
        color: #fff;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 1.92px;
        font-weight: 500;
        @include touch {
          font-size: 15px;
          line-height: 20px;
        }
        .content {
          padding: 16.5px 30px 16.5px 68px;
          text-indent: -2rem;
          word-break: break-word;
          @include touch {
            padding: 1.5rem 1.5rem 1.5rem 3.55rem;
            text-indent: -2.05rem;
          }
          &:before {
            content: 'A:';
            font-family: Palatino, sans-serif;
            margin-right: 0.5rem;
            letter-spacing: 3px;
            font-size: 16px;
            @include touch {
              font-size: 18px;
            }
          }
          a {
            color: #fff;
            text-decoration: underline;
          }
        }
      }
    }
  }
</style>
